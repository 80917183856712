// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const API_URL = process.env.REACT_APP_API_URL;

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;
export const MAP_API = process.env.REACT_APP_GOOGLE_MAP_API;

// console.log("From config global ", process.env);

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'

// ROOT PATH BEFORE LOGIN SUCCESSFUL
export const PATH_BEFORE_LOGIN = paths.auth.jwt.login; // login
