import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtForgotPasswordPage = lazy(() => import('src/pages/auth/jwt/forgot-password'));
const JwtConfirmAccountPage = lazy(() => import('src/pages/auth/jwt/create-account'));
const JwtPassResetPage = lazy(() => import('src/pages/auth/jwt/pass-reset'));

const authJwt = {
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout title="Project Antelope">
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'forgot-password',
      element: (
        <AuthClassicLayout title="Project Antelope">
          <JwtForgotPasswordPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'create/:hash/:id',
      element: (
        <AuthClassicLayout title="Project Antelope">
          <JwtConfirmAccountPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'pass-reset/:userId/:hash/:id',
      element: (
        <AuthClassicLayout title="Project Antelope">
          <JwtPassResetPage />
        </AuthClassicLayout>
      ),
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <Outlet />
      </GuestGuard>
    ),
    children: [authJwt],
  },
];
