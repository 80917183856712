import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import servicerReducer from './slices/servicer'


// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const rootReducer = combineReducers({
  servicer: servicerReducer,
});
