import { createSlice } from '@reduxjs/toolkit';
// featherjs
import feathersClient from 'src/feathers';

// ----------------------------------------------------------------------

const initialState = {
  allServicers: [],
  filteredServicerss: {
      servicers: [],
      allLatLng: [],
  },
  servicersStatus: {
    loading: false,
    empty: false,
    error: null,
  }
};

const slice = createSlice({
  name: 'servicers',
  initialState,
  reducers: {
    // GET SERVICERS
    getServicersStart(state) {
      state.servicersStatus.loading = true;
      state.servicersStatus.empty = false;
      state.servicersStatus.error = null;
    },
    getServicersFailure(state, action) {
      state.servicersStatus.loading = false;
      state.servicersStatus.empty = false;
      state.servicersStatus.error = action.payload;
    },
    getServicersSuccess(state, action) {
      const servicers = action.payload;

      state.allServicers = servicers;

      state.servicersStatus.loading = false;
      state.servicersStatus.empty = !servicers.length;
      state.servicersStatus.error = null;
    },

    // FILTERED SERVICERS
    setFilteredServicers(state, action) {
      const filteredServicers = action.payload;
      state.filteredServicerss.allLatLng = filteredServicers.allLatLng;
      state.filteredServicerss.servicers = filteredServicers.servicers;
    },
    resetFilteredServicers(state) {
      state.filteredServicerss.servicers = [];
      state.filteredServicerss.allLatLng = [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setFilteredServicers,
  resetFilteredServicers
} = slice.actions;

// ----------------------------------------------------------------------

export function getAllServicers() {
  return async (dispatch) => {
    dispatch(slice.actions.getServicersStart());
    try {
      const response = await feathersClient.service('servicers').find({});
      dispatch(slice.actions.getServicersSuccess(response));
    } catch (error) {
      dispatch(slice.actions.getServicersFailure(error));
    }
  };
}


