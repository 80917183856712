import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));


// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// SERVICERS
const ServicerListPage = lazy(() => import('src/pages/dashboard/servicers/list'));
const ServicerCreatePage = lazy(() => import('src/pages/dashboard/servicers/new'));
const ServicerProfilePage = lazy(() => import('src/pages/dashboard/servicers/profile'));
const ServicerEditPage = lazy(() => import('src/pages/dashboard/servicers/edit'));
const WorkOrderCreatePage = lazy(() => import('src/pages/dashboard/servicers/createWorkOrder'));
// WORK ORDER
const WorkOrderListPage = lazy(() => import('src/pages/dashboard/workOrder/list'));
const WorkOrderDetailsPage = lazy(() => import('src/pages/dashboard/workOrder/details'));
const WorkOrderEditPage = lazy(() => import('src/pages/dashboard/workOrder/edit'));
// SYSTEM INFO
const SystemInfoPage = lazy(() => import('src/pages/dashboard/systemInfo/systemInfo'))
// const SystemInfoListPage = lazy(() => import('src/pages/dashboard/systemInfo/list'))
// const SystemInfoCreatePage = lazy(() => import('src/pages/dashboard/systemInfo/new'));


// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
        ],
      },
      {
        path: 'servicers', 
        children: [
          { element: <ServicerListPage />, index: true },
          { path: ':id', element: <ServicerProfilePage /> },
          { path: 'new', element: <ServicerCreatePage /> },
          { path: ':id/edit', element: <ServicerEditPage /> },
          { path: 'create-work-order/:id', element: <WorkOrderCreatePage /> },
        ],
      },
      {
        path: 'work-order',
        children: [
          { element: <WorkOrderListPage />, index: true },
          { path: ':id', element: <WorkOrderDetailsPage /> },
          { path: ':id/edit', element: <WorkOrderEditPage /> },
        ]
      },
      { path: 'system-info', element: <SystemInfoPage /> },
      // {
      //   path: 'system-info',
      //   children: [
      //     { element: <SystemInfoListPage />, index: true },
      //     { path: 'list', element: <SystemInfoListPage /> },
      //   ],
      // },
    ],
  },
];
