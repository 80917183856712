import feathers, {authentication} from '@feathersjs/client';
import axios from 'axios';

import { API_URL } from 'src/config-global';

const app = feathers();
const feathersClient = app
  .configure(feathers.rest(API_URL).axios(axios))
  .configure(authentication());

export default feathersClient;
