import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('overview'),
        items: [
          { title: t('dashboard'), path: paths.dashboard.root, icon: ICONS.dashboard },
          // { title: t('ecommerce'), path: paths.dashboard.general.ecommerce, icon: ICONS.ecommerce },
          // { title: t('analytics'), path: paths.dashboard.general.analytics, icon: ICONS.analytics },
          // { title: t('banking'), path: paths.dashboard.general.banking, icon: ICONS.banking },
          // { title: t('booking'), path: paths.dashboard.general.booking, icon: ICONS.booking },
          // { title: t('file'), path: paths.dashboard.general.file, icon: ICONS.file },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('management'),
        items: [
          // USER
          {
            title: t('users'),
            path: paths.dashboard.user.list,
            icon: ICONS.user,
            roles: 'ADMIN',
          },
          // {
          //   title: t('users'),
          //   path: paths.dashboard.user.root,
          //   icon: ICONS.user,
          //   roles:'ADMIN',
          //   children: [
          //     // { title: t('profile'), path: paths.dashboard.user.root },
          //     { title: t('list'), path: paths.dashboard.user.list, roles:'ADMIN', },
          //     { title: t('create'), path: paths.dashboard.user.new, roles:'ADMIN' },
          //     // { title: t('edit'), path: paths.dashboard.user.demo.edit },
          //   ],
          // },
          // SERVICERS
          {
            title: t('servicers'),
            path: paths.dashboard.service.root,
            icon: ICONS.job,
          },
          // {
          //   title: t('servicers'),
          //   path: paths.dashboard.service.root,
          //   icon: ICONS.job,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.service.root },
          //     // { title: t('details'), path: paths.dashboard.service.demo.details },
          //     { title: t('create'), path: paths.dashboard.service.new },
          //   ],
          // },
          // WORK ORDER
          {
            title: t('work orders'),
            path: paths.dashboard.workOrder.root,
            icon: ICONS.order,
          },
          // SYSTEM INFO
          {
            title: t('system info'),
            path: paths.dashboard.systemInfo.root,
            icon: ICONS.blog,
            roles:'ADMIN',
          },
          // {
          //   title: t('system info'),
          //   path: paths.dashboard.systemInfo.root,
          //   roles:'ADMIN',
          //   icon: ICONS.blog,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.systemInfo.root, roles:'ADMIN', },
          //     // { title: t('details'), path: paths.dashboard.post.demo.details },
          //     // { title: t('create'), path: paths.dashboard.systemInfo.new, roles:'ADMIN', },
          //     // { title: t('edit'), path: paths.dashboard.post.demo.edit },
          //   ],
          // },
        ],
      },
    ],
    [t]
  );

  return data;
}
