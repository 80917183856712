import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const ServicerWorkOrderDetailsPage = lazy(() => import('src/pages/servicer/workOrder/workOrderDetails'));

// ----------------------------------------------------------------------

export const servicerRoutes = [
  {
    path: 'servicer',
    element: (
        <Suspense fallback={<LoadingScreen />}>
            <Outlet />
        </Suspense>
    ),
    children: [{ path: ':id', element: <ServicerWorkOrderDetailsPage /> },],
  },
];
